
/* Général */
$body_background_color : #f4f4f4;
$body_texte_color: #222; 
$content_background_color: #f4f4f4;
$link_color : #222;
$link_hover_color : #298dff;
$button_background_color : #298dff;
$button_border_color : #298dff;
$button_color : #fff;
$button_hover_background_color : #fff;
$button_hover_border_color : #298dff;
$button_hover_color : #298dff;
$h2_color : #fff;
$h2_background_color : #298dff;
 
/* Header */
$header_background_color : #fff;
$slogan_color : #222;
$menu_background_color : #fff;
$nav_background_color : #222;
$nav_color : #fff;
$nav_actif_background_color : #298dff;
$nav_actif_color : #fff;
$menu_burger_color : #298dff;
$menu_burger_hover_background_color : #298dff;
$menu_burger_hover_color : #fff;

/* Content */
$sidebar_rubrique_color : #222;
$sidebar_rubrique_border_color : #222;
$fil_darianne_actif_color : #298dff;
$form_perso_background_color : #fafafa;

/* accueil */
$prod_accueil_background_color : rgba(41, 141, 255, .6);
$prod_accueil_color : #fff;
$slider_titre_background_color : rgba(41, 141, 255, .6);

/* boutique */
$produit_color : #222;
$etiquette_background_color : #298dff;
$etiquette_color : #fff;
$remise_color : #298dff;
$zoom_background_color : #298dff;
$prix_color : #298dff;
$old_prix_color : #b1b2b6;

$ficheprod_remise_background_color : #298dff;

/* livre d'or */
$msglo_h2_background_color : #555;
$msglo_h2_color : #fff;
$msglo_note_background_color : #298dff; 
$msglo_note_color : #fff;

/* contact */
$form_focus_shadow: 0 0 5px #298dff;
$form_focus_bordercolor: #298dff;


/* Footer */
$footer_color : #fff;
$footer_background_color : #292628;

/* Blog */
$background_blog_menu :  #298dff !important;
$background_blog_h2 : #298dff;
$white : #fff !important;

/* Template Panier */
$panier-background-color:#fff;
$panier-titre-color:#000;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/745series/declinaison";